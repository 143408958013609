import React, { forwardRef, useContext, useRef, useState } from "react";
import Button from "../../components/button";
import Hero from "../../components/hero";
import Helpers from "../../components/helpers";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/navbar";
import Footer from "../../components/footer";
import FormattedMsg from "../../locale/components/formatted-msg";
import ConfigContext from "../../contexts/config-context";
import Logger from "../../components/logger/logger";
import ConfigHelper from "../../config/config-helper";
import Card from "../../components/card";
import InputText from "../../components/input-text";
import LocaleContext from "../../contexts/locale-context";
import pageStyle from "../../styles/page.module.css";
import style from "../../styles/pages/jasmine/details.module.css";
import FullPageLoading from "../full-page-loading";

/*
Reference:
<form action="/action_page.php" autocomplete="on">

<label for=“imei”>IMEI:</label>
<input type="text" id=“imei” name=“imei” autocomplete=“device-imei”><br><br>
<label for=“eid”>EID:</label>
<input type="text" id=“eid” name=“eid” autocomplete=“device-eid”><br><br>

</form>
 */

const EIDInput = forwardRef(function ({ disabled, className, ...rest }, ref) {
  const { formatMessage } = useContext(LocaleContext);
  return (
    <InputText
      label={formatMessage("Your_eID_headline")}
      type="text"
      id="eid"
      name="eid"
      inputMode="numeric"
      autoComplete="device-eid"
      disabled={!!disabled}
      className={`${style.autofillInput} ${className || ""}`}
      ref={ref}
      {...rest}
    />
  );
});

const IMEIInput = forwardRef(function ({ disabled, className, ...rest }, ref) {
  const { formatMessage } = useContext(LocaleContext);
  return (
    <InputText
      label={formatMessage("Your_IMEI_headline")}
      type="text"
      id="imei"
      name="imei"
      inputMode="numeric"
      autoComplete="device-imei"
      disabled={!!disabled}
      className={`${style.autofillInput} ${className || ""}`}
      ref={ref}
      {...rest}
    />
  );
});

const elementMatches = (element, selectors) => {
  if (element && selectors) {
    const classes = selectors instanceof Array ? selectors : [selectors];
    return classes.find((selector) => {
      try {
        return element.matches(selector);
      } catch (error) {
        Logger.d("Error checking selector", element, selector, error.message);
        return false;
      }
    });
  }
};

const isAutofill = (element) => {
  return elementMatches(element, [":autofill", ":-webkit-autofill"]);
};

const isEidValid = (appleWebview, eidValue, lastEidAutoFillValue) => {
  return (
    eidValue &&
    (appleWebview
      ? eidValue === lastEidAutoFillValue
      : ConfigHelper.isSafariAutofillCheckDisabled()
      ? eidValue?.length === 32
      : false)
  );
};

const isImeiValid = (appleWebview, imeiValue, lastImeiAutoFillValue) => {
  return (
    imeiValue &&
    (appleWebview
      ? imeiValue === lastImeiAutoFillValue
      : ConfigHelper.isSafariAutofillCheckDisabled()
      ? imeiValue?.length === 15
      : false)
  );
};

const onSubmitButtonClick = (event, test, eidValue, imeiValue, config) => {
  let alertMessage = null;
  if (test) {
    //show an alert to be used during testing
    alertMessage = `Fake submit eid="${eidValue}" imei="${imeiValue}"`;
  } else {
    if (!eidValue) {
      alertMessage = "No eID provided!";
    } else if (!imeiValue) {
      alertMessage = "No IMEI provided!";
    } else {
      //redirect to the gatekeeper with the given eid and imei
      const gkUrl = config.network.endpoints.gatekeeper?.url;
      if (!gkUrl) {
        alertMessage = "No URL configured for gatekeeper, cannot proceed";
      } else {
        const savedParams = new URLSearchParams(
          Helpers.getSavedLocationParams() || ""
        );
        const country = savedParams.get("country");
        const clientId = savedParams.get("clientId");
        if (!country) {
          alertMessage = "No country parameter found";
        } else if (!clientId) {
          alertMessage = "No clientId parameter found";
        } else {
          //everything ok
          savedParams.delete("clientId");
          const url = `${gkUrl}/${clientId}?eid=${eidValue}&imei=${imeiValue}&${savedParams.toString()}`;
          Promise.resolve().then(() => {
            Logger.d("Redirecting to ", url);
            window.location.replace(url);
          });
        }
      }
    }
  }
  if (alertMessage) {
    //Promise is used to not have the alert blocking the event (prevent Chrome warnings)
    Promise.resolve().then(() => {
      window.alert(alertMessage);
    });
  }
};

export default function SubmitDetailsPage() {
  const location = useLocation();
  const [eidValue, setEidValue] = useState("");
  const [imeiValue, setImeiValue] = useState("");
  const [lastEidAutoFillValue, setLastEidAutoFillValue] = useState(null);
  const [lastImeiAutoFillValue, setLastImeiAutoFillValue] = useState(null);
  const [, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { config } = useContext(ConfigContext);
  const hidden = !!Helpers.getUrlParameter("hidden", location);
  const disabled = !!Helpers.getUrlParameter("disabled", location);
  const test = !!Helpers.getUrlParameter("test", location);
  const eidRef = useRef();
  const imeiRef = useRef();
  const submitRef = useRef();
  const { formatMessage } = useContext(LocaleContext);
  if (isAutofill(eidRef?.current) && eidValue !== lastEidAutoFillValue) {
    setLastEidAutoFillValue(eidValue);
  }
  if (isAutofill(imeiRef?.current) && imeiValue !== lastImeiAutoFillValue) {
    setLastImeiAutoFillValue(imeiValue);
  }
  const ua = navigator.userAgent.toLowerCase();
  const appleWebview =
    ua.includes("ipad") || (ua.includes("macintosh") && !ua.includes("chrome"));
  const eidValid = isEidValid(appleWebview, eidValue, lastEidAutoFillValue);
  const imeiValid = isImeiValid(appleWebview, imeiValue, lastImeiAutoFillValue);
  const submitButtonEnabled = eidValid && imeiValid;
  const showEidHint =
    document.activeElement &&
    document.activeElement === eidRef?.current &&
    !eidValid;
  const showImeiHint =
    document.activeElement &&
    document.activeElement === imeiRef?.current &&
    !imeiValid;
  return loading ? (
    <FullPageLoading />
  ) : (
    <>
      <NavBar />
      <Hero className={style.hero} textId="Submit_eID_and_IMEI_Hero_headline" />
      <div
        className={`${style.container} ${style.pageContainer} ${style.withCards}`}
        data-testid={"submit-details-page-container"}
      >
        <div className={pageStyle.content}>
          <form
            className={style.form}
            style={hidden ? { display: "none" } : {}}
            action=""
            onSubmit={(event) => {
              //prevent the submission to be sent to the action
              event.preventDefault();

              setLoading(true);
              setTimeout(
                () =>
                  onSubmitButtonClick(event, test, eidValue, imeiValue, config),
                250
              );
            }}
            autoComplete="on"
          >
            <Card className={style.card}>
              <p>
                <FormattedMsg id="Submit_eID_and_IMEI_Copy" />
              </p>
              <EIDInput
                data-testid="eid-input"
                disabled={disabled}
                ref={eidRef}
                value={eidValue}
                errorMessage={
                  eidValue && !eidValid
                    ? formatMessage("Autofill_eID_alert_copy")
                    : null
                }
                hint={
                  showEidHint ? formatMessage("Autofill_eID_alert_copy") : null
                }
                onChange={(e) => {
                  setEidValue(e.target.value);
                  //verify that the field becomes autofilled at some time
                  setTimeout(() => {
                    //ask for a refresh to get the autofill value reflected in react state
                    setLastUpdate(new Date());
                  });
                }}
                onFocus={() => {
                  setLastUpdate(new Date());
                }}
                onBlur={() => {
                  setLastUpdate(new Date());
                }}
              />
              {test && lastEidAutoFillValue && (
                <p>LAST EID AUTOFILLED: {lastEidAutoFillValue}</p>
              )}
              <IMEIInput
                data-testid="imei-input"
                hidden={hidden}
                disabled={disabled}
                ref={imeiRef}
                value={imeiValue}
                errorMessage={
                  imeiValue && !imeiValid
                    ? formatMessage("Autofill_IMEI_alert_copy")
                    : null
                }
                hint={
                  showImeiHint
                    ? formatMessage("Autofill_IMEI_alert_copy")
                    : null
                }
                onChange={(e) => {
                  setImeiValue(e.target.value);
                  //verify that the field becomes autofilled at some time
                  setTimeout(() => {
                    //ask for a refresh to get the autofill value reflected in react state
                    setLastUpdate(new Date());
                  });
                }}
                onBlur={() => {
                  setLastUpdate(new Date());
                }}
                onFocus={() => {
                  setLastUpdate(new Date());
                }}
              />
              {test && lastImeiAutoFillValue && (
                <p>LAST IMEI AUTOFILLED: {lastImeiAutoFillValue}</p>
              )}
              <Button
                className={style.mainButton}
                data-testid="submit-button"
                onClick={() => {
                  submitRef.current.click();
                }}
                disabled={!submitButtonEnabled}
              >
                <FormattedMsg id="Submit_button" />
              </Button>
            </Card>
            <button type="submit" style={{ display: "none" }} ref={submitRef} />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
