import React, { useContext, useState, useRef, useEffect, useMemo } from "react";
import { DatePicker } from "../../../components/datepicker";
import { NewSubscriptionPage } from "./newSubscriptionPage";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormattedMsg from "../../../locale/components/formatted-msg";
import Divider from "../../../components/divider";
import Button from "../../../components/button";
import LocaleContext from "../../../contexts/locale-context";
import { InfoIcon } from "../../../components/icons/info-icon";
import { NationDropdown } from "../../../components/nation-input";
import ValidatedInputText from "../../../components/input-validators/validated-input-text";
import createDobValidator from "../../../components/input-validators/dob-validator";
import createNationalityValidator from "../../../components/input-validators/nationality-validator";

import style from "../../../styles/form.module.css";
import { OverlayContext } from "../../../contexts/overlay-context";
import { createNameValidator } from "../../../components/input-validators/name-validator";

const getLastEligibleDate = () => {
  const date = new Date();

  // if the current year is leap, use Feb, 28th as reference
  if (date.getDate() === 29 && date.getMonth() === 1) {
    date.setDate(28);
  }
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

const PersonalInformation = (props) => {
  const inputs = useRef({
    firstName: useRef(null),
    lastName: useRef(null),
    dob: useRef(null),
    nationality: useRef(null),
  }).current;

  const {
    formData: outletFormData,
    setData,
    setPageData,
    pages,
  } = useOutletContext();
  const { selectPlan, personalInformation: data } = outletFormData;
  const { formatMessage, localeCountryList } = useContext(LocaleContext);
  const { showOverlay, overlay } = useContext(OverlayContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    dob: data.dob ? new Date(data.dob) : null,
    nationality: data.nationality || "",
  });

  const validators = useMemo(
    () => ({
      firstName: createNameValidator(
        "first",
        outletFormData?.paymentDetails?.firstName,
        "First_name_not_matching_input_error",
        overlay
      ),
      lastName: createNameValidator(
        "last",
        outletFormData?.paymentDetails?.lastName,
        "Last_name_not_matching_input_error",
        overlay
      ),
      dob: createDobValidator(
        "Invalid_date_input_field_label",
        "Too_recent_date_of_birth_label",
        getLastEligibleDate
      ),
      nationality: createNationalityValidator(
        "Invalid_country_name_input_error_message",
        localeCountryList
      ),
    }),
    [overlay, outletFormData, localeCountryList]
  );

  // check whether all fields are filled and valid
  const [submittable, setSubmittable] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const requiredFields = ["firstName", "lastName", "dob"];

    const isSubmittable = requiredFields.every((field) => {
      const fieldValue = formData[field];
      if (
        !fieldValue ||
        (typeof fieldValue === "string" && fieldValue.trim() === "")
      ) {
        return false;
      }
      const validator = validators[field];
      const isValid = validator.isSubmittable(fieldValue, 3, null, true);
      return isValid;
    });

    const hasErrors = requiredFields.some((field) => {
      const fieldValue = formData[field];

      if (field === "dob") {
        if (!fieldValue || isNaN(Date.parse(fieldValue))) {
          return true;
        }
      }

      if (
        !fieldValue ||
        (typeof fieldValue === "string" && fieldValue.trim() === "")
      ) {
        return false;
      }

      const validator = validators[field];
      const hasError = !validator.isSubmittable(fieldValue, 3, null, true);
      return hasError;
    });

    if (isSubmittable !== submittable) {
      setSubmittable(isSubmittable);
      setData("submittable", isSubmittable, false);
    }

    if (hasErrors !== hasError) {
      setHasError(hasErrors);
      setData("hasError", hasErrors, false);
    }
  }, [
    validators,
    formData,
    setData,
    submittable,
    hasError,
    outletFormData,
    data,
    overlay,
  ]);

  const handleInput = (field, value) => {
    setFormData((prev) => {
      let res = { ...prev };
      res[field] = value;
      return res;
    });

    if (value instanceof Date) {
      setData("raw_datestring", inputs.dob.current.value);
      value = value.toDateString();
    }
    setData(field, value);
  };

  return (
    <NewSubscriptionPage
      heroTitle={{ textId: "Contact_details_hero_title" }}
      plan={selectPlan?.plan}
      containerClassName="TestPi"
    >
      <div className={style.container}>
        <h1 className={style.title}>
          <FormattedMsg id="Personal_information_page_title" />
        </h1>
        <div className={style.row} data-testid="personal-information-form">
          <ValidatedInputText
            ref={inputs.firstName}
            focusedHint={formatMessage("First_name_input_hint")}
            onBlur={() => {
              const formFirstName = outletFormData?.paymentDetails?.firstName;

              if (formFirstName && formFirstName !== formData.firstName) {
                showOverlay("yes-no-alert", {
                  titleId: "Save_changes_personal_information_Dialog_Headline",
                  descriptionId:
                    "Save_changes_personal_information_Dialog_Copy",
                  yesButtonId: "Account_holder_name_overlay_Save_button",
                  noButtonId: "Account_holder_name_overlay_Discard_button",
                  onYes: () => {
                    setData("firstName", formData.firstName, false);
                    setPageData();
                    showOverlay();
                  },
                  onNo: (_, viaIcon) => {
                    if (!viaIcon) {
                      setFormData((prev) => ({
                        ...prev,
                        firstName: formFirstName,
                      }));
                      setData("firstName", formFirstName);
                      showOverlay();
                    } else {
                      showOverlay();
                      inputs.firstName.current.focus();
                    }
                  },
                  onClose: () => {},
                });
              }
            }}
            data-testid="first-name-input"
            value={formData.firstName}
            label={formatMessage("First_name_input_label") + "*"}
            onChange={(e) => handleInput("firstName", e.target.value)}
            validator={validators.firstName}
            minLength={3}
            required={true}
          />
          <ValidatedInputText
            ref={inputs.lastName}
            focusedHint={formatMessage("Last_name_input_hint")}
            onBlur={() => {
              const formLastName = outletFormData?.paymentDetails?.lastName;

              if (formLastName && formLastName !== formData.lastName) {
                showOverlay("yes-no-alert", {
                  titleId: "Save_changes_personal_information_Dialog_Headline",
                  descriptionId:
                    "Save_changes_personal_information_Dialog_Copy",
                  yesButtonId: "Account_holder_name_overlay_Save_button",
                  noButtonId: "Account_holder_name_overlay_Discard_button",
                  onYes: () => {
                    setData("lastName", formData.lastName, false);
                    setPageData();
                    showOverlay();
                  },
                  onNo: (_, viaIcon) => {
                    if (!viaIcon) {
                      setFormData((prev) => ({
                        ...prev,
                        lastName: formLastName,
                      }));
                      setData("lastName", formLastName);
                      showOverlay();
                    } else {
                      showOverlay();
                      inputs.lastName.current.focus();
                    }
                  },
                  onClose: () => {},
                });
              }
            }}
            data-testid="last-name-input"
            value={formData.lastName}
            label={formatMessage("Last_name_input_label") + "*"}
            onChange={(e) => handleInput("lastName", e.target.value)}
            validator={validators.lastName}
            minLength={3}
            required={true}
          />
        </div>
        <div className={`${style.row}`}>
          <DatePicker
            data-testid="dob-input"
            ref={inputs.dob}
            value={formData.dob}
            separator={
              Array.from(data["raw_datestring"] ?? [])?.find((c) =>
                /\D/.test(c)
              ) || "."
            }
            label={formatMessage("Date_of_birth_input_label") + "*"}
            validator={validators.dob}
            headlines={{
              year: formatMessage("Year_picker_title"),
              month: formatMessage("Month_picker_title"),
              day: formatMessage("Day_picker_title"),
            }}
            buttonsLabels={{
              next: {
                year: formatMessage("Select_year_button"),
                month: formatMessage("Select_month_button"),
                day: formatMessage("Confirm_selected_date_button"),
              },
              back: {
                year: "",
                month: formatMessage("Back_to_year_button"),
                day: formatMessage("Back_to_month_button"),
              },
            }}
            infoLabel={formatMessage("Birthday_date_hint_copy")}
            upperBound={getLastEligibleDate()}
            focusedHint={formatMessage("Date_of_birth_hint")}
            onChange={(date) => {
              handleInput("dob", date);
            }}
            onSelected={() => {
              if (!inputs.nationality.current?.value)
                inputs.nationality.current.focus();
            }}
            required={true}
          />
          <NationDropdown
            ref={inputs.nationality}
            data-testid="nationality-input"
            value={formData.nationality}
            onChange={(v) => {
              handleInput("nationality", v);
            }}
            required={true}
          />
        </div>
        <div className={style["info-row"]}>
          <InfoIcon className={style.infoIcon} />
          <div className={style.info}>
            <p>
              <FormattedMsg id="Nationality_format_hint" />
            </p>
          </div>
        </div>
        <div className={style.footer}>
          <div className={style.row}>
            <Divider className={style.divider} />
          </div>
          <div className={style.row}>
            <div className={style.buttonsContainer}>
              <Button
                data-testid="next-button"
                data-link-id="next_button"
                variant={submittable && !hasError ? "primary" : "disabled"}
                onClick={() => {
                  setPageData();
                  navigate(pages.NewSubscription.HomeAddress.full);
                }}
                className={style.submitButton}
              >
                <FormattedMsg id="Next_CTA" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </NewSubscriptionPage>
  );
};

export default PersonalInformation;
