import createDefaultTextValidator from "./default-text-validator";
import createValidator from "./validator";

export function createNameValidator(
  kind,
  expectedValue,
  matchErrorMessageId,
  overlay
) {
  const textValidator = createDefaultTextValidator(
    `Short_${kind}_name_input_error`,
    `Invalid_${kind}_name_input_error`
  );

  return createValidator(
    (value, focused, minLength, maxLength, required) => {
      const defaultRes = textValidator.validationFunction(
        value,
        focused,
        minLength,
        maxLength,
        required
      );

      if (overlay) return;

      if (!expectedValue || defaultRes) return defaultRes;

      if (!focused && expectedValue !== value) {
        return {
          messageId: matchErrorMessageId,
        };
      } else {
        return;
      }
    },
    (value, minLength, maxLength, required, data) => {
      return (
        textValidator.submittableFunction(
          value,
          minLength,
          maxLength,
          required,
          data
        ) && (expectedValue ? expectedValue === value : true)
      );
    }
  );
}
