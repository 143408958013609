import ConfigHelper from "../../config/config-helper";
import Logger from "../logger/logger";
import createValidator from "./validator";

const nameRegex = /^[A-Za-z.'\-0-9 äöüÄÖÜ]+$/;

export default function createDefaultTextValidator(
  shortErrorMessage,
  invalidErrorMessage,
  validRegex,
  regexStopSubmittable
) {
  return createValidator(
    (value, focused, minLength, maxLength, required) => {
      let result;

      // if the input is made only of spaces,
      // show invalid input error
      if (value && value.trim().length === 0) {
        return {
          messageId: invalidErrorMessage,
        };
      }

      value = value?.trim();
      const regex = validRegex || nameRegex;
      if (value?.length > 0) {
        const tooShort = minLength
          ? value.replace(" ", "").length < minLength
          : false;
        if (tooShort && !focused) {
          //too short
          result = {
            messageId: shortErrorMessage,
          };
        } else if (!regex.test(value)) {
          result = {
            messageId: invalidErrorMessage,
          };
        }
      }
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.v(
          `Text validator.getError value = "${value}" regex = ${
            validRegex ? "ext" : "default"
          }, regexTest=${regex.test(
            value
          )}, focused=${focused} minLength=${minLength} maxLength=${maxLength} required=${required} result=${result}`
        );
      }
      return result;
    },
    (value, minLength, maxLength, required) => {
      let result;
      value = value?.trim() || value;
      const validLength =
        (minLength ? value.length >= minLength : true) &&
        (maxLength ? value.length <= maxLength : true);
      const regex = validRegex || nameRegex;
      const validText = regex.test(value);
      if (required) {
        result = validText && validLength && value.length > 0;
      } else if (value.length === 0) {
        result = true;
      } else if (regexStopSubmittable) {
        //should test for regex and set to not submittable if regex is not ok
        result = validText && validLength;
      } else {
        //not required, some value, should not stop the submittable
        result = true;
      }
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.v(
          `Text validator.isSubmittable value = "${value}" regex = ${
            validRegex ? "ext" : "default"
          }, validText=${validText} validLenght=${validLength} minLenght=${minLength} maxLength=${maxLength} required=${required} result=${result}`
        );
      }
      return result;
    }
  );
}
