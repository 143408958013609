import React, { useState, useContext, useEffect } from "react";
import { NewSubscriptionPage } from "./newSubscriptionPage";
import { useNavigate, useOutletContext } from "react-router-dom";
import FormattedMsg from "../../../locale/components/formatted-msg";
import Divider from "../../../components/divider";
import Button from "../../../components/button";
import Address from "./address";
import { Checkbox } from "../../../components/checkbox";
import LocaleContext from "../../../contexts/locale-context";
import createAddressValidators from "../../../components/input-validators/address-validators";
import Logger from "../../../components/logger/logger";
import ConfigHelper from "../../../config/config-helper";
import PlacesLoader from "../../../components/places/places-loader";
import { OverlayContext } from "../../../contexts/overlay-context";

import style from "../../../styles/form.module.css";
import { useMemo } from "react";

const INVOICE_KEY = "useSameAddressAsInvoice";
const acLoading = {};

export default function HomeAddress() {
  const {
    formData: outletFormData,
    setData,
    setPageData,
    pages,
  } = useOutletContext();
  const { formatMessage } = useContext(LocaleContext);
  const { showOverlay } = useContext(OverlayContext);
  const { selectPlan, homeAddress: data } = outletFormData;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    postcode: data.postcode || "",
    city: data.city || "",
    street: data.street || "",
    houseNumber: data.houseNumber || "",
    secondLine: data.secondLine || "",
    useSameAddressAsInvoice:
      data.useSameAddressAsInvoice !== undefined
        ? data.useSameAddressAsInvoice
        : true,
    invoice_postcode: data.invoice_postcode || "",
    invoice_city: data.invoice_city || "",
    invoice_street: data.invoice_street || "",
    invoice_houseNumber: data.invoice_houseNumber || "",
    invoice_secondLine: data.invoice_secondLine || "",
    //validation
    postcodeValidated: data.postcodeValidated || false,
    cityValidated: data.cityValidated || false,
    streetValidated: data.streetValidated || false,
    houseNumberValidated: data.houseNumberValidated || false,
    invoice_postcodeValidated: data.invoice_postcodeValidated || false,
    invoice_cityValidated: data.invoice_cityValidated || false,
    invoice_streetValidated: data.invoice_streetValidated || false,
    invoice_houseNumberValidated: data.invoice_houseNumberValidated || false,
  });

  const maxLengths = useMemo(
    () => ({
      postcode: 5,
      city: 40,
      street: 33,
      houseNumber: 9,
      secondLine: 40,
    }),
    []
  );

  const minLengths = useMemo(
    () => ({
      postcode: 5,
      city: 2,
      street: 2,
      houseNumber: 1,
    }),
    []
  );

  const requiredFields = useMemo(
    () => ({
      postcode: true,
      city: true,
      street: true,
      houseNumber: true,
    }),
    []
  );

  //clean up loading on mount
  useEffect(() => {
    acLoading.postcode =
      acLoading.city =
      acLoading.street =
      acLoading.houseNumber =
        null;
  }, []);

  const setValidated = (field, value) => {
    setFormData((prev) => {
      let res = { ...prev };
      res[field] = value;
      res[field + "Validated"] = value;
      return res;
    });
    setData(field + "Validated", value);
    setData(field, value);
  };

  const isValidated = (field, value) => {
    return (value ?? formData[field]) === formData[field + "Validated"];
  };

  const setLoading = (field, value) => {
    acLoading[field] = value;
  };

  const isLoading = (field) => {
    return acLoading[field];
  };

  const validators = createAddressValidators(
    setValidated,
    isValidated,
    ConfigHelper.isLocationVerificationEnabled(),
    setLoading,
    isLoading
  );
  const invoiceValidators = createAddressValidators(
    setValidated,
    isValidated,
    ConfigHelper.isLocationVerificationEnabled(),
    setLoading,
    isLoading,
    "invoice_"
  );

  const handleInput = (field, value) => {
    if (!field || value == null) {
      return;
    }

    const fieldsToBeErased =
      field === INVOICE_KEY
        ? [
            "invoice_postcode",
            "invoice_city",
            "invoice_street",
            "invoice_houseNumber",
            "invoice_secondLine",
          ]
        : [];
    setFormData((prev) => {
      let res = { ...prev };
      res[field] = value;
      for (const f of fieldsToBeErased) {
        res[f] = "";
        //remove validation data as well
        res[f + "Validated"] = null;
      }
      return res;
    });

    // cleanup fields if they're not submittable,
    // useful for back button / progress indicator
    if (
      (!submittable || !isValidated(field, value ?? "")) &&
      value !== data[field]
    ) {
      setData(field, undefined, false);
    }
    setData(field, value);

    for (const f of fieldsToBeErased) {
      setData(f, "");
      //remove validation data as well
      setData(f + "Validated", null);
    }
  };

  // check whether all fields are filled and valid
  const [submittable, setSubmittable] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const isSubmittable = Object.keys(validators).every((field) => {
      const validator = validators[field];
      const invoice_field = `invoice_${field}`;
      const invoiceValidator = invoiceValidators[field];
      const invoiceSelected = formData[INVOICE_KEY];
      const submittable = validator.isSubmittable(
        formData[field],
        minLengths[field],
        maxLengths[field],
        requiredFields[field]
      );
      const invoiceSubmittable =
        invoiceSelected ||
        invoiceValidator.isSubmittable(
          formData[invoice_field],
          minLengths[field],
          maxLengths[field],
          requiredFields[field]
        );
      const result = submittable && invoiceSubmittable;
      if (ConfigHelper.isValidatorsLogsEnabled()) {
        Logger.d(
          `Homeaddress submittable: Field ${field} submittable=${submittable} ${
            !invoiceSelected
              ? `and ${invoice_field} submittable=${invoiceSubmittable}`
              : ""
          } is ${result ? "" : "NOT "}submittable req=${requiredFields[field]}`,
          invoiceSelected
        );
      }
      return result;
    });

    const hasErrors = Object.keys(validators).some((field) => {
      const value = formData[field];
      const invoice_field = `invoice_${field}`;
      const invoiceValue = formData[invoice_field];
      const invoiceSelected = formData[INVOICE_KEY];

      const hasHomeAddressError =
        value &&
        !validators[field].isSubmittable(
          value,
          minLengths[field],
          maxLengths[field],
          requiredFields[field]
        );

      const hasInvoicingAddressError =
        !invoiceSelected &&
        invoiceValue &&
        !invoiceValidators[field].isSubmittable(
          invoiceValue,
          minLengths[field],
          maxLengths[field],
          requiredFields[field]
        );

      return hasHomeAddressError || hasInvoicingAddressError;
    });

    setSubmittable(isSubmittable);
    setHasError(hasErrors);

    if (isSubmittable !== submittable) {
      setData("submittable", isSubmittable, false);
    }
    if (hasErrors !== hasError) {
      setData("hasError", hasErrors, false);
    }
  }, [
    validators,
    formData,
    setData,
    submittable,
    hasError,
    invoiceValidators,
    maxLengths,
    minLengths,
    requiredFields,
  ]);

  // Function to handle the "Back" button
  const handleBackClick = () => {
    // If there are errors and the fields have values, show the discard overlay
    if (hasError) {
      showOverlay("discard-changes", {
        onMainAction: () => {
          setFormData((prev) => {
            const newFormData = { ...prev };
            Object.keys(validators).forEach((field) => {
              if (
                validators[field] &&
                !validators[field].isSubmittable(
                  formData[field],
                  minLengths[field],
                  maxLengths[field],
                  requiredFields[field]
                )
              ) {
                newFormData[field] = "";
              }
            });

            if (!formData[INVOICE_KEY]) {
              Object.keys(invoiceValidators).forEach((field) => {
                const invoiceField = `invoice_${field}`;
                if (
                  invoiceValidators[field] &&
                  !invoiceValidators[field].isSubmittable(
                    formData[invoiceField],
                    minLengths[field],
                    maxLengths[field],
                    requiredFields[field]
                  )
                ) {
                  newFormData[invoiceField] = "";
                }
              });
            }

            return newFormData;
          });
          setPageData(false);
          showOverlay();
          navigate(pages.NewSubscription.PersonalInformation.full);
        },
      });
    } else {
      setPageData();
      navigate(pages.NewSubscription.PersonalInformation.full);
    }
  };

  return (
    <NewSubscriptionPage
      heroTitle={{ textId: "Contact_details_hero_title" }}
      plan={selectPlan?.plan}
    >
      <div className={style.container}>
        <h1 className={style.title}>
          <FormattedMsg id="Home_address_page_title" />
        </h1>
        <PlacesLoader provider="locationiq">
          <Address
            postcode={formData["postcode"]}
            setPostcode={(value) => handleInput("postcode", value)}
            city={formData["city"]}
            setCity={(value) => handleInput("city", value)}
            street={formData["street"]}
            setStreet={(value) => handleInput("street", value)}
            houseNumber={formData["houseNumber"]}
            setHouseNumber={(value) => handleInput("houseNumber", value)}
            secondLine={formData["secondLine"]}
            setSecondLine={(value) => handleInput("secondLine", value)}
            testId="homeAddress"
            key="home_address"
            rowStyle={style.row}
            boxStyle={style.box}
            smallboxStyle={style.smallbox}
            validators={validators}
            minLengths={minLengths}
            requiredFields={requiredFields}
            maxLengths={maxLengths}
          />
          <div className={`${style.row} ${style.bigMargin}`}>
            <Checkbox
              data-testid="use-same-address-as-invoice-checkbox"
              checked={formData[INVOICE_KEY]}
              label={
                <span>{formatMessage("Invoicing_address_checkbox_copy")}</span>
              }
              onChange={(e) => handleInput(INVOICE_KEY, e.target.checked)}
            />
          </div>
          {formData[INVOICE_KEY] ? null : (
            <>
              <h1 className={style.title}>
                <FormattedMsg id="Invoicing_address_page_title" />
              </h1>
              <Address
                postcode={formData["invoice_postcode"]}
                setPostcode={(value) => handleInput("invoice_postcode", value)}
                city={formData["invoice_city"]}
                setCity={(value) => handleInput("invoice_city", value)}
                street={formData["invoice_street"]}
                setStreet={(value) => handleInput("invoice_street", value)}
                houseNumber={formData["invoice_houseNumber"]}
                setHouseNumber={(value) =>
                  handleInput("invoice_houseNumber", value)
                }
                secondLine={formData["invoice_secondLine"]}
                setSecondLine={(value) =>
                  handleInput("invoice_secondLine", value)
                }
                testId="invoiceAddress"
                key="invoice_address"
                rowStyle={style.row}
                boxStyle={style.box}
                smallboxStyle={style.smallbox}
                validators={invoiceValidators}
                minLengths={minLengths}
                maxLengths={maxLengths}
                requiredFields={requiredFields}
              />
            </>
          )}
          <div className={style.footer}>
            <div className={style.row}>
              <Divider className={style.divider} />
            </div>
            <div className={style.buttonsContainer}>
              <Button
                data-testid="back-button"
                data-link-id="back_button"
                variant={"tertiary"}
                onClick={handleBackClick}
              >
                <FormattedMsg id="Secondary_action" />
              </Button>

              <Button
                data-testid="next-button"
                data-link-id="next_button"
                variant={submittable ? "primary" : "disabled"}
                onClick={() => {
                  setPageData();
                  navigate(pages.NewSubscription.ContactDetails.full);
                }}
                className={style.submitButton}
              >
                <FormattedMsg id="Next_CTA" />
              </Button>
            </div>
          </div>
        </PlacesLoader>
      </div>
    </NewSubscriptionPage>
  );
}
