import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import formStyle from "../../../styles/form.module.css";
import style from "../../../styles/pages/contactDetails.module.css";
import pageStyle from "../../../styles/page.module.css";
import otpStyle from "../../../styles/OTP.module.css";
import FormattedMsg from "../../../locale/components/formatted-msg";
import Divider from "../../../components/divider";
import { Checkbox } from "../../../components/checkbox";
import Button from "../../../components/button";
import { generateOTPData } from "../../../components/network/email";
import useNetwork from "../../../components/network/use-network";
import { useContext } from "react";
import LocaleContext from "../../../contexts/locale-context";
import ConfigContext from "../../../contexts/config-context";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import { ErrorPage } from "./errorPage";
import { CallHandler } from "../../../components/call-handler";
import { OverlayContext } from "../../../contexts/overlay-context";
import { TickIcon } from "../../../components/icons/tick-icon";
import { ThemeContext } from "../../../contexts/theme-context";
import { PhoneNumberInput } from "../../../components/phone-number-input";
import createEmailValidator from "../../../components/input-validators/email-validator";
import ValidatedInputText from "../../../components/input-validators/validated-input-text";
import createPhoneNumberValidator from "../../../components/input-validators/phone-number-validator";
import Helpers from "../../../components/helpers";

const generateOTPTag = "generateOTP";

export default function ContactDetailsContent() {
  const { config } = useContext(ConfigContext);
  const { checkNetworkCall, startNetworkCall, removeNetworkCall } =
    useNetwork();
  const navigate = useNavigate();
  const { formatMessage } = useContext(LocaleContext);
  const { showOverlay } = useContext(OverlayContext);
  const {
    formData: outletFormData,
    getGenerateOTPData,
    setData,
    setPageData,
    setGenerateOTPResult,
    onStartGenerateOTPcall,
    pages,
  } = useOutletContext();
  const { selectPlan, contactDetails: data } = outletFormData;
  const plan = selectPlan?.plan;

  const emailValidator = createEmailValidator();
  const phoneNumberValidator = createPhoneNumberValidator();

  const [formData, setFormData] = useState({
    email: data?.email || "",
    phoneNumber: data?.phoneNumber || "",
    vodafoneThirdAdv: data?.vodafoneThirdAdv || false,
    vodafoneDataPermission: data?.vodafoneDataPermission || false,
  });
  const [, setTick] = useState();
  const [submittable, setSubmittable] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { theme } = useContext(ThemeContext);

  const generateOTPSavedData = getGenerateOTPData();
  const generateOTPcooldown = generateOTPSavedData?.cooldown || 0;
  const now = Date.now();
  const timeToWaitForGenerateOTPCall = generateOTPcooldown
    ? Math.max(Math.round((generateOTPcooldown - now) / 1000), 0)
    : 0;

  useEffect(() => {
    if (timeToWaitForGenerateOTPCall > 0) {
      //set an interval
      const interval = setInterval(() => {
        try {
          setTick(Date.now());
        } catch (error) {}
      }, 500);
      return () => {
        try {
          clearInterval(interval);
        } catch (error) {}
      };
    }
  }, [timeToWaitForGenerateOTPCall]);

  const [emailVerifiedIndicator, setEmailVerifiedIndicator] = useState(
    Helpers.getWidth() > 657
      ? "E-mail_verified_input_indicator"
      : "E-mail_verified_input_indicator_small"
  );
  useEffect(() => {
    const listener = () => {
      const width = Helpers.getWidth();
      if (width > 657)
        setEmailVerifiedIndicator("E-mail_verified_input_indicator");
      else if (width <= 657)
        setEmailVerifiedIndicator("E-mail_verified_input_indicator_small");
    };
    window.addEventListener("resize", listener);

    return () => window.removeEventListener("resize", listener);
  }, []);

  useEffect(() => {
    const isEmailSubmittable = emailValidator.isSubmittable(
      formData.email,
      null,
      null,
      true
    );

    const phoneNumber = formData.phoneNumber;
    const isValidPhoneNumber =
      phoneNumber === "" || /^\+?\d{1,4}?[\d\s]+$/.test(phoneNumber);

    const isFormSubmittable = isEmailSubmittable; // && (!phoneNumber || isValidPhoneNumber);

    if (isFormSubmittable !== submittable) {
      setSubmittable(isFormSubmittable);
    }

    const hasErrors =
      (formData.email.trim() !== "" && !isEmailSubmittable) ||
      (formData.phoneNumber.trim() !== "" && !isValidPhoneNumber);

    if (hasErrors !== hasError) {
      setHasError(hasErrors);
      setData("hasError", hasErrors, false);
    }

    if (data.submittable !== isFormSubmittable) {
      setData("submittable", isFormSubmittable, false);
    }
  }, [emailValidator, formData, submittable, hasError, setData, data]);

  if (!plan) {
    return <Navigate to={pages.NewSubscription.SelectPlan.full} />;
  }

  const handleInput = (field, value) => {
    setFormData((prev) => {
      let res = { ...prev };
      res[field] = value;
      return res;
    });

    setData(field, value);
  };

  const handleBackClick = () => {
    // If there are errors and the fields have values, show the discard overlay
    if (hasError) {
      showOverlay("discard-changes", {
        onMainAction: () => {
          // Clear fields with errors
          setFormData((prev) => {
            let newFormData = { ...prev };

            if (
              !emailValidator.isSubmittable(newFormData.email, null, null, true)
            ) {
              newFormData.email = "";
            }

            const phoneNumber = newFormData.phoneNumber;
            if (phoneNumber && !/^\+?\d{1,4}?[\d\s]+$/.test(phoneNumber)) {
              newFormData.phoneNumber = "";
            }

            return newFormData;
          });
          setPageData(false);
          showOverlay();
          navigate(pages.NewSubscription.HomeAddress.full);
        },
      });
    } else {
      setPageData();
      navigate(pages.NewSubscription.HomeAddress.full);
    }
  };

  const generateOTPcall = checkNetworkCall(generateOTPTag, true, false);
  const generateOTP = () => {
    removeNetworkCall(generateOTPTag, true);
    //force update to show loading
    onStartGenerateOTPcall(formData.email);
    //start Call
    return startNetworkCall(
      generateOTPTag,
      () => generateOTPData(config, formData.email),
      true,
      false
    );
  };

  const generateOTPCallback = (result) => {
    if (setGenerateOTPResult({ email: formData.email, ...result })) {
      if (!(result instanceof Error)) {
        // setPageData();
        navigate(pages.NewSubscription.OTPVerification.full);
      }
      // // nextPage();
      return true;
    }
  };

  if (generateOTPcall?.hasError()) {
    const error = generateOTPcall?.read();
    if (error.status === 423) {
      const errorChildren =
        timeToWaitForGenerateOTPCall > 0 ? (
          <p className={`${style.link} ${otpStyle.linkTooManyOtps} disabled`}>
            <FormattedMsg
              id="Resend_OTP_timer_copy"
              values={{
                nn: (
                  <span className={otpStyle.secondsLeft}>
                    {timeToWaitForGenerateOTPCall}
                  </span>
                ),
              }}
            />
          </p>
        ) : null;

      const errorIDs = {
        titleId: "Too_many_OTP_attempts_page_title",
        descriptionId: "Too_many_OTP_attempts_page_copy",
        mainButtonId: "Send_new_OTP",
        secondaryActionId: "Quit_setup_button",
        email: formData.email,
        hidePrimaryButton: timeToWaitForGenerateOTPCall > 0,
        primaryAction: () => {
          generateOTP(formData.email);
        },
        heroImage: "tooManyAttemptsOTP",
      };
      return <ErrorPage {...errorIDs}>{errorChildren}</ErrorPage>;
    } else
      return (
        <ErrorPage
          status={error.status}
          heroImage="failedOTPGeneration"
          primaryAction={() => window.location.reload()}
        />
      );
  }

  return (
    <NewSubscriptionPage
      heroTitle={{ textId: "Contact_details_hero_title" }}
      plan={plan}
    >
      {generateOTPcall?.isPending() ? (
        <CallHandler call={generateOTPcall} onFinish={generateOTPCallback} />
      ) : (
        <div className={formStyle.container}>
          <h1 className={formStyle.title}>
            <FormattedMsg id="Contact_details_page_title" />
          </h1>
          <div
            className={formStyle.container}
            data-testid="contact-details-form"
          >
            <div className={formStyle.row}>
              <ValidatedInputText
                // prevents the focus on input
                // otherwise, the vkeyboard would be triggered
                // while showing the overlay
                onPointerDownCapture={(e) => {
                  if (
                    data.emailVerified === formData.email &&
                    document.activeElement !== e.target
                  )
                    e.preventDefault();
                }}
                onClick={(e) => {
                  if (
                    data.emailVerified === formData.email &&
                    document.activeElement !== e.target
                  ) {
                    e.preventDefault();
                    showOverlay("yes-no-alert", {
                      titleId: "Change_E-mail_overlay_title",
                      descriptionId: "Change_E-mail_overlay_copy",
                      yesButtonId: "Change_email_button",
                      noButtonId: "Cancel_button",
                      onYes: () => {
                        showOverlay();
                        e.target.focus();
                      },
                      onNo: () => {
                        showOverlay();
                      },
                    });
                  }
                }}
                data-testid="email-input"
                value={formData.email}
                label={formatMessage("E-mail_Address_input_field_label") + "*"}
                hideIcons={data.emailVerified === formData.email}
                action={{
                  content:
                    data.emailVerified === formData.email ? (
                      <div className={style.emailVerifiedIndicator}>
                        <TickIcon
                          className={style.indicatorIcon}
                          active
                          variant={theme === "light" ? "green" : "blue"}
                        />
                        <p className={style.indicatorText}>
                          <FormattedMsg id={emailVerifiedIndicator} />
                        </p>
                      </div>
                    ) : null,
                }}
                onChange={(e) => handleInput("email", e.target.value)}
                required={true}
                validator={emailValidator}
                type="email"
              />
            </div>
            <div className={formStyle.row}>
              <PhoneNumberInput
                value={formData.phoneNumber}
                label={formatMessage("Phone_number_input_field_label")}
                onChange={(v) => handleInput("phoneNumber", v)}
                validator={phoneNumberValidator}
              />
            </div>
          </div>
          <div className={`${formStyle.row}`}>
            <Checkbox
              size="small"
              data-testid="vodafone-third-adv-checkbox"
              checked={formData.vodafoneThirdAdv}
              id="vodafone-third-adv"
              onChange={(e) =>
                handleInput("vodafoneThirdAdv", e.target.checked)
              }
            >
              <div className={formStyle.info}>
                <FormattedMsg
                  id="Contact_Marketing_legal_copy"
                  values={{
                    Products_and_services_overlay: (
                      <span
                        className={pageStyle.link}
                        data-testid="products-and-services-overlay"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          showOverlay("info-overlay", {
                            title: formatMessage(
                              "Products_and_services_overlay_title"
                            ),
                            text: formatMessage(
                              "Products_and_services_overlay_copy"
                            ),
                          });
                        }}
                      >
                        <FormattedMsg id="Products_and_services_overlay_label" />
                      </span>
                    ),
                    Vodafone_companies_overlay: (
                      <span
                        data-testid="products-and-services-overlay"
                        className={pageStyle.link}
                        onClick={(e) => {
                          e.preventDefault();
                          showOverlay("info-overlay", {
                            title: formatMessage(
                              "Vodafone_companies_overlay_title"
                            ),
                            text: formatMessage(
                              "Vodafone_companies_overlay_copy"
                            ),
                          });
                        }}
                      >
                        <FormattedMsg id="Vodafone_companies_overlay_label" />
                      </span>
                    ),
                  }}
                />
              </div>
            </Checkbox>
          </div>
          <div className={`${formStyle.row}`}>
            <Checkbox
              size="small"
              data-testid="vodafone-data-permission-checkbox"
              checked={formData.vodafoneDataPermission}
              id="vodafone-data-permission"
              vodafone="vodafone-data-permission"
              onChange={(e) =>
                handleInput("vodafoneDataPermission", e.target.checked)
              }
            >
              <div className={formStyle.info}>
                <p>
                  <FormattedMsg
                    id="Location_Marketing_legal_copy"
                    values={{
                      Vodafone_companies_overlay: (
                        <span
                          data-testid="vodafone-companies-overlay"
                          className={pageStyle.link}
                          onClick={(e) => {
                            e.preventDefault();
                            showOverlay("info-overlay", {
                              title: formatMessage(
                                "Vodafone_companies_overlay_title"
                              ),
                              text: formatMessage(
                                "Vodafone_companies_overlay_copy"
                              ),
                            });
                          }}
                        >
                          <FormattedMsg id="Vodafone_companies_overlay_label" />
                        </span>
                      ),
                      Usage_data_overlay: (
                        <span
                          data-testid="usage-data-overlay"
                          className={pageStyle.link}
                          onClick={(e) => {
                            e.preventDefault();
                            showOverlay("info-overlay", {
                              title: formatMessage("Usage_data_overlay_title"),
                              text: formatMessage("Usage_data_overlay_copy"),
                            });
                          }}
                        >
                          <FormattedMsg id="Usage_data_overlay_label" />
                        </span>
                      ),
                      Traffic_data_overlay: (
                        <span
                          data-testid="traffic-data-overlay"
                          className={pageStyle.link}
                          onClick={(e) => {
                            e.preventDefault();
                            showOverlay("info-overlay", {
                              title: formatMessage(
                                "Traffic_data_overlay_title"
                              ),
                              text: formatMessage("Traffic_data_overlay_copy"),
                            });
                          }}
                        >
                          <FormattedMsg id="Traffic_data_overlay_label" />
                        </span>
                      ),
                    }}
                  />
                </p>
                <p>
                  <FormattedMsg id="Revoke_consent_legal_copy" />
                </p>
              </div>
            </Checkbox>
          </div>
          <div className={formStyle.footer}>
            <div className={formStyle.row}>
              <Divider />
            </div>
            <div className={formStyle.row}>
              <div className={formStyle.buttonsContainer}>
                <Button
                  data-testid="back-button"
                  variant="tertiary"
                  onClick={handleBackClick}
                  data-link-id="back_button"
                >
                  <FormattedMsg id="Secondary_action" />
                </Button>
                <Button
                  data-testid="next-button"
                  data-link-id="next_button"
                  variant={submittable ? "primary" : "disabled"}
                  onClick={() => {
                    if (!submittable) {
                      return;
                    }

                    setPageData();

                    if (data.emailVerified === formData.email) {
                      navigate(pages.NewSubscription.CreatePassword.full);
                      return;
                    }

                    // Start generate OTP call
                    if (data.email !== getGenerateOTPData()?.email) {
                      generateOTP();
                      navigate(pages.NewSubscription.OTPVerification.full);
                    } else {
                      navigate(pages.NewSubscription.OTPVerification.full);
                    }
                  }}
                >
                  <FormattedMsg id="Next_CTA" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </NewSubscriptionPage>
  );
}
