import { useNavigate, useOutletContext } from "react-router-dom";
import Hero from "./hero";
import FormattedMsg from "../locale/components/formatted-msg";
import { ReactComponent as SelectIcon } from "../images/progress-select.svg";
import { ReactComponent as DefineIcon } from "../images/progress-define.svg";
import { ReactComponent as SecureIcon } from "../images/progress-secure.svg";
import { ReactComponent as SubscribeIcon } from "../images/progress-subscribe.svg";

import style from "../styles/new-subscription-hero.module.css";
import { useContext } from "react";
import { OverlayContext } from "../contexts/overlay-context";

function ProgressBar({
  highlighted,
  submittable,
  hasError,
  onClick,
  disabled,
}) {
  return (
    <div
      onClick={disabled ? null : onClick} // Disable clicking if it's not submittable
      className={`${style.progressBar} ${
        highlighted ? style.highlighted : submittable ? style.submittable : ""
      } ${hasError ? style.error : ""}`}
      data-testid="progress-bar"
      data-status={highlighted ? true : null}
      style={{ pointerEvents: disabled ? "none" : "auto" }} // Disable interaction for future steps
    />
  );
}

function ProgressIndicator({
  titleId,
  title,
  icon,
  subSteps = 1,
  currentIndicator = false,
  order,
  currentSubStep,
  isLast,
  pages,
  onClick,
  submittable,
  hasError,
  "data-testid": dataTestId = null,
}) {
  const bars = [];
  for (let i = 0; i < subSteps; i++) {
    bars.push(
      <ProgressBar
        key={`pb${currentIndicator ? "-hl" : ""}-${i}`}
        highlighted={
          (currentIndicator === order && i <= currentSubStep) ||
          order < currentIndicator
        }
        submittable={
          (submittable[i] || isLast[i]) &&
          (order > currentIndicator ||
            (currentIndicator === order && i > currentSubStep))
        }
        hasError={hasError[i]}
        onClick={onClick(pages[i])}
      />
    );
  }
  return (
    <div className={style.progressIndicator} data-testid={dataTestId}>
      <div
        className={`${style.iconAndTitle}`}
        onClick={() => {
          if (pages) {
            if (pages.length === 1) {
              onClick(pages[0])();
            } else {
              const lastIndex = pages.findIndex((_, i) => !submittable[i]);

              if (currentSubStep > order) {
                onClick(pages[order])();
              } else if (lastIndex === -1 || lastIndex >= currentSubStep) {
                onClick(pages[currentSubStep])();
              }
            }
          }
        }}
      >
        {icon && <div className={style.icon}>{icon}</div>}
        <p
          className={`${style.title} ${
            currentIndicator === order && style.current
          }`}
          data-testid="progress-title"
        >
          {titleId ? <FormattedMsg id={titleId} /> : title || null}
        </p>
      </div>
      {bars?.length > 0 ? (
        <div className={style.progressBars} data-testid="progress-bars">
          {bars}
        </div>
      ) : null}
    </div>
  );
}

function ProgressSteps({
  page,
  data,
  temp,
  setPageData,
  pages: flowPages,
  steps: pages,
}) {
  let currentSubSteps = [];
  let current = "";

  const options = [
    {
      submittable: [data[pages[0]]?.submittable],
      hasError: [data[pages[0]]?.hasError],
      data: [data[pages[0]]],
    },
    {
      submittable: [
        data[pages[1]]?.submittable,
        data[pages[2]]?.submittable,
        data[pages[3]]?.submittable,
      ],
      hasError: [
        data[pages[1]]?.hasError,
        data[pages[2]]?.hasError,
        data[pages[3]]?.hasError,
      ],
      data: [data[pages[1]], data[pages[2]], data[pages[3]]],
    },
    {
      submittable: [data[pages[4]]?.submittable],
      hasError: [data[pages[4]]?.hasError],
      data: [data[pages[4]]],
    },
    {
      submittable: [data[pages[5]]?.submittable],
      hasError: [data[pages[5]]?.hasError],
      data: [data[pages[5]]],
    },
  ];

  switch (page) {
    case flowPages.NewSubscription.SelectPlan.relative:
      currentSubSteps = [0];
      current = 0;
      break;
    case flowPages.NewSubscription.PersonalInformation.relative:
      currentSubSteps = [0, 0];
      current = 1;
      break;
    case flowPages.NewSubscription.HomeAddress.relative:
      currentSubSteps = [0, 1];
      current = 1;
      break;
    case flowPages.NewSubscription.ContactDetails.relative:
    case flowPages.NewSubscription.OTPVerification.relative:
      currentSubSteps = [0, 2];
      current = 1;
      break;
    case flowPages.NewSubscription.CreatePassword.relative:
      currentSubSteps = [0, 2, 0];
      current = 2;
      break;
    case flowPages.NewSubscription.PaymentDetails.relative:
      currentSubSteps = [0, 2, 0, 0];
      current = 3;
      break;
    default:
  }

  const navigate = useNavigate();
  const { showOverlay } = useContext(OverlayContext);
  const hasTemp = Object.keys(temp).length > 0;

  const submittable = options[current].submittable[currentSubSteps[current]];
  const hasError = options[current].hasError[currentSubSteps[current]];
  const pageData = options[current].data[currentSubSteps[current]];
  const last = data.step ?? 0;

  const onClick = (redirectTo) => (e) => {
    const index = pages.findIndex(
      (s) => s === redirectTo.split("/").slice(-1)[0]
    );

    if (index !== -1 && index <= last && pages[index] !== page) {
      const isFieldEmpty = pageData
        ? Object.values(pageData).every((page) =>
            Object.values(page || {}).every((field) => !field?.value?.trim())
          )
        : true;

      const isModified = pageData
        ? Object.values(pageData).some((page) =>
            Object.values(page || {}).some((field) => {
              return (
                field?.value !== field?.initialValue && field?.value !== ""
              );
            })
          )
        : false;

      if (hasError) {
        showOverlay("discard-changes", {
          onMainAction: () => {
            setPageData(false); // Discard current data
            showOverlay();
            navigate(redirectTo);
          },
        });
      } else if (submittable || (!submittable && !isFieldEmpty && isModified)) {
        if (hasTemp) {
          showOverlay("unsaved-changes", {
            onMainAction: () => {
              setPageData();
              showOverlay();
              navigate(redirectTo);
            },
            onSecondaryAction: () => {
              setPageData(false);
              showOverlay();
              navigate(redirectTo);
            },
          });
        } else {
          redirectTo && navigate(redirectTo);
        }
      } else {
        navigate(redirectTo);
      }
    }
  };

  const currentIcon = [
    <SelectIcon className={style.icon} />,
    <DefineIcon className={style.icon} />,
    <SecureIcon className={style.icon} />,
    <SubscribeIcon className={style.icon} />,
  ][current];

  return (
    <div className={style.stepsContainer}>
      <div className={style.compactSelector}>
        <div className={style.icon}>{currentIcon}</div>
        <p className={`${style.title}`} data-testid="progress-title">
          <FormattedMsg
            id={
              [
                "Progress_indicator_Select_title",
                "Progress_indicator_Define_title",
                "Progress_indicator_Secure_title",
                "Progress_indicator_Subscribe_title",
              ][current]
            }
          />
        </p>
      </div>
      <div className={style.steps} data-testid="progress-indicators">
        <ProgressIndicator
          titleId="Progress_indicator_Select_title"
          icon={<SelectIcon className={style.icon} />}
          subSteps={1}
          currentSubStep={currentSubSteps[0]}
          currentIndicator={current}
          order={0}
          pages={[flowPages.NewSubscription.SelectPlan.full]}
          onClick={onClick}
          data-testid="progress-indicator-select"
          isLast={[last === 0]}
          {...options[0]}
        />
        <ProgressIndicator
          titleId="Progress_indicator_Define_title"
          icon={<DefineIcon className={style.icon} />}
          subSteps={3}
          currentSubStep={currentSubSteps[1]}
          onClick={onClick}
          pages={[
            flowPages.NewSubscription.PersonalInformation.full,
            flowPages.NewSubscription.HomeAddress.full,
            flowPages.NewSubscription.ContactDetails.full,
          ]}
          currentIndicator={current}
          order={1}
          isLast={[last === 1, last === 2, last === 3]}
          data-testid="progress-indicator-define"
          {...options[1]}
        />
        <ProgressIndicator
          titleId="Progress_indicator_Secure_title"
          icon={<SecureIcon className={style.icon} />}
          subSteps={1}
          currentSubStep={currentSubSteps[2]}
          pages={[flowPages.NewSubscription.CreatePassword.full]}
          currentIndicator={current}
          order={2}
          onClick={onClick}
          isLast={[last === 4]}
          data-testid="progress-indicator-secure"
          {...options[2]}
        />
        <ProgressIndicator
          titleId="Progress_indicator_Subscribe_title"
          icon={<SubscribeIcon className={style.icon} />}
          subSteps={1}
          currentSubStep={currentSubSteps[3]}
          pages={[flowPages.NewSubscription.PaymentDetails.full]}
          onClick={onClick}
          currentIndicator={current}
          order={3}
          isLast={[last === 5]}
          data-testid="progress-indicator-subscribe"
          {...options[3]}
        />
      </div>
    </div>
  );
}

export default function NewSubscriptionHero() {
  const context = useOutletContext();
  const page = context?.page || null;
  const data = context?.formData;
  const steps = context?.steps;
  const { temp } = data?.[page];

  return page ? (
    <Hero className={style.hero}>
      <ProgressSteps
        page={page}
        data={data}
        temp={temp}
        setPageData={context?.setPageData}
        pages={context?.pages}
        steps={steps}
      />
    </Hero>
  ) : null;
}
